import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database'

let config = require('./../../config/config')

let firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  measurementId: config.firebase.measurementId
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth=firebase.auth();
const kioskCollection = db.collection('kiosks');
const mailCollection = db.collection('mail');
const qnaCollection = db.collection('qna');
const odysseyCollection = db.collection('odyssey');
const telepresenceCollection = db.collection('telepresence');
const registeredUsersCollection = db.collection('registeredUsers');
const appDataCollection = db.collection('appData');
const groupCollection = db.collection('userGroups')


export {
  db,
  auth,
  firebase,
  kioskCollection,
  mailCollection,
  qnaCollection,
  odysseyCollection,
  telepresenceCollection,
  registeredUsersCollection,
  appDataCollection,
  groupCollection
}
