<template>
    <v-snackbar color="error" timeout=10000 rounded="pill" v-model="snackBar.show" top>
        {{ snackBar.text }}
        <template v-slot:action="{ attrs }">
            <v-btn icon dark v-bind="attrs" @click="snackBar.show = false">
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'snackBar',
    computed: {
        ...mapGetters(['snackBar'])
    },

}
</script>