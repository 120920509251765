<template>
    <v-row no-gutters class="fill-height">
        <v-col cols="6">
            <avatar :initialWidth="avatarWidth"></avatar>
        </v-col>
        <v-col cols="6" align-self="center" align="center" justify="center">
            <v-card flat width="300">
                <v-text-field label="Enter Code" v-model="startCode" rounded outlined dense append-icon="mdi-send"
                    @click:append="verifyCode"></v-text-field>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import avatar from "@/components/avatar.vue";
import { mapGetters } from 'vuex';
export default {
    name: 'screenSaver',
    data() {
        return {
            startCode: null
        }
    },
    components: {
        avatar
    },
    computed: {
        ...mapGetters(['appDimensions']),
        avatarWidth() {
            console.log('Avatar Width: ', this.appDimensions.width / 2)
            return this.appDimensions.width / 2
        }
    },
    methods: {
        verifyCode() {
            if (this.startCode === '9989') {
                this.$store.dispatch('updateKioskProfile').then(() => {
                    this.$store.commit('setScreenSaver', false)
                    this.$router.push('/')
                })
            }
        }
    },
    mounted(){
        this.$store.commit("setTextBubbleText", "The kiosk application is under maintenance.")
    }
}
</script>