<template>
  <v-dialog v-model="getEmailViewer.show" :max-width="width" persistent class="overflow-y-none">
    <v-card class="pa-5 containerBorder">
      <v-row class="mb-2">
        <v-col cols="11" align="center" align-self="center">
          <span class="text-h5 font-weight-bold">
            Link to {{ getEmailViewer.pdfName }}</span>
        </v-col>
        <v-col cols="1" align="right" align-self="center">
          <v-icon dark large color="error" @click="closeDialog('Close')">mdi-close</v-icon>
        </v-col>
        <v-row no-gutters>
          <v-col cols="12">
            <v-divider class="mx-1"></v-divider>
          </v-col>
        </v-row>
      </v-row>
      <v-row no-gutters v-if="showQrcode" class="mt-2">
        <v-col cols="8">
          <v-form ref="form">
            <v-row justify="center" no-gutters class="fill-height">
              <v-col cols="10">
                <v-text-field required placeholder="username@domain.com" outlined :rules="emailRules"
                  v-model="emailAddress" class="ml-3 text-h5">
                </v-text-field>
              </v-col>
              <v-col cols="2" align-self="start">
                <v-btn x-large color="primary" class="ml-4" @click="closeDialog('Send')">
                  {{ $t("sendbutton") }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <SimpleKeyboard keyboardClass="email-keyboard" @onChange="onChange" @onKeyPress="onKeyPress"
                  :input="emailAddress">
                </SimpleKeyboard>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="1" align="center">
          <v-row no-gutters class="fill-height">
            <v-col cols="12">
              <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="12" align-self="center" class="font-weight-bold">
              OR
            </v-col>
            <v-col cols="12">
              <v-divider vertical></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" align="center">
          <v-card flat width="300" height="320" class="containerBorder1">
            <v-card-text class="font-weight-bold mb-0 pt-1 pb-0 ">{{ $t('qrcodetext') }}</v-card-text>
            <v-card-actions class="justify-center pa-0 ma-0">
              <qrcode-vue :value="value" :size="size" level="H" class="px-6 pt-2" />
            </v-card-actions>
            <v-card-text class="pt-0 mt-0 px-1 font-weight-bold">{{ getEmailViewer.pdfUrl | trimLength}}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="mt-2">
        <v-col cols="12">
          <v-form ref="form">
            <v-row justify="center" no-gutters class="fill-height">
              <v-col cols="10">
                <v-text-field required placeholder="username@domain.com" outlined :rules="emailRules"
                  v-model="emailAddress" class="ml-3 text-h5">
                </v-text-field>
              </v-col>
              <v-col cols="2" align-self="start">
                <v-btn x-large color="primary" class="ml-4" @click="closeDialog('Send')">
                  {{ $t("sendbutton") }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="">
                <SimpleKeyboard keyboardClass="email-keyboard" @onChange="onChange" @onKeyPress="onKeyPress"
                  :input="emailAddress">
                </SimpleKeyboard>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <!-- <v-row no-gutters>
        <v-col cols="12">
          <v-divider class="mx-1 my-2"></v-divider>
        </v-col>
        <v-col cols="12" align="center" class="grey--text font-weight-bold text-h5">
          {{ getEmailViewer.pdfUrl }}
        </v-col>
      </v-row> -->
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import { mapGetters } from "vuex";
import QrcodeVue from 'qrcode.vue'
export default {
  name: "emailViewer",
  data() {
    return {
      emailAddress: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      emailText: "",
      pdfUrl: "",
      pdfName: "",
      showQrcode: false,
      value: '',
      size: 225,
      width: "50%"
    };
  },
  components: {
    SimpleKeyboard,
    QrcodeVue
  },
  computed: {
    ...mapGetters(["getEmailViewer"]),
  },
  watch: {
    getEmailViewer(newVal) {
      if (newVal.pdfType === 'eforms' || newVal.pdfType === 'link') {
        this.showQrcode = true
        this.value = newVal.pdfUrl
        this.width = "75%"
      }
    }
  },
  methods: {
    onChange(input) {
      this.emailAddress = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}") {
        this.emailAddress = this.emailAddress.slice(0, -1);
      }
    },
    closeDialog(action) {
      this.showQrcode = false
      this.width = "50%"
      if (action === "Send") {
        switch (this.getEmailViewer.pdfType.toLowerCase()) {
          case "eforms":
            this.emailText =
              "Following is the link to the Digital forms requested,";
            this.pdfUrl = this.getEmailViewer.pdfUrl;
            break;
          case "pdf":
            this.emailText =
              "Following is the link to the form requested,";
            this.pdfUrl = this.getEmailViewer.pdfUrl;
            break;
          case "packets":
            this.emailText =
              "Following is the link to the Form Packet requested,";
            this.pdfUrl = this.getEmailViewer.pdfUrl;
            break;
          case "link":
            this.emailText =
              "Following is the requested link,";
            this.pdfUrl = this.getEmailViewer.pdfUrl;
            break;
          default:
            break;
        }
        if (this.emailAddress === "") {
          this.$store.dispatch("avatarSpeak", this.$i18n.t("noemail"));
        } else {
          let validate = this.$refs.form.validate();
          if (validate) {
            this.$store
              .dispatch("sendEmail", {
                address: this.emailAddress,
                subject: "Requested Document - Maryland Courts",
                content:
                  "Hello," +
                  "<br>" +
                  "<br>" +
                  this.emailText +
                  "<br>" +
                  "<br>" +
                  "<a href='" + this.pdfUrl + "'>" + this.pdfUrl + "</a>" +
                  "<br>" +
                  "<br> Thank you," +
                  "<br><strong>Maryland Courts</strong>",
              })
              .then(
                (response) => {
                  this.$store.commit("closeEmailViewer");
                  this.$store.dispatch(
                    "avatarSpeak",
                    this.$i18n.t("successemailresponse")
                  );
                  this.emailAddress = "";
                  // Touch usage
                  this.$store.commit("setTouchRequest", {
                    module: "Email Viewer",
                    action: "Email PDF",
                    response: this.pdfName,
                    timeStamp: new Date(),
                    requestType: "Email",
                  });
                },
                (error) => {
                  this.$store.dispatch(
                    "avatarSpeak",
                    this.$i18n.t("erroremailresponse")
                  );
                  this.emailAddress = "";
                }
              );
          } else {
            this.$store.dispatch("avatarSpeak", this.$i18n.t("invalidemail"));
          }
        }
      } else if (action === "Close") {
        this.$store.commit("closeEmailViewer");
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 50) {
        return val;
      }
      return `${val.substring(0, 45)}...`;
    },
  },
};
</script>

<style scoped></style>
<i18n>
{
  "en": {
      "sendbutton": "Send",
      "successemailresponse": "Got it! you will receive the email shortly.",
      "erroremailresponse": "Oops! There is some problem with email service. Try again later.",
      "noemail": "Email ID cannot be empty.",
      "invalidemail": "Please enter a valid Email ID.",
      "qrcodetext": "SCAN THE QR CODE TO GET THE LINK"
    },
  "es":{
      "sendbutton": "Enviar",
      "successemailresponse": "¡Entiendo! recibirá el correo electrónico en breve.",
      "erroremailresponse": "Oops! Hay algún problema con el servicio de correo electrónico. Vuelve a intentarlo más tarde.",
      "noemail": "El ID de correo electrónico no puede estar vacío.",
      "invalidemail": "Ingrese un ID de correo electrónico válido.",
      "qrcodetext": "ESCANEA EL CÓDIGO QR PARA OBTENER EL ENLACE"
    }
}
</i18n>