<template>
    <v-dialog v-model="getJuryImageViewer.show" width="70%" persistent>
        <v-card width="100%">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-card-title>
                        <v-row>
                            <v-col align="center" cols="11">
                                <span class="text-h5 pl-16 font-weight-bold">{{ getJuryImageViewer.name }}</span>
                            </v-col>
                            <v-col cols="1" align="center" class="pa-0">
                                <v-progress-circular @click="$store.commit('closeJuryImageViewer')" :rotate="180"
                                    :size="50" :width="7" :value="closePercentage" color="error" class="my-2">
                                    <v-icon large color="error"> mdi-close </v-icon>
                                </v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider class="mx-2"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" class="mb-10">
                            <v-img :src="getImage(getJuryImageViewer.mapname)" width="98%"></v-img>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from 'qrcode.vue';
export default {
    name: "imageViewer",
    data() {
        return {
            closePercentage: 0,
            timeout: 0,
            intervalObj: 0,
            value: '',
            size: 225,
        };
    },
    components: {
        QrcodeVue
    },
    computed: {
        ...mapGetters(["getJuryImageViewer", "getIsSpeaking"]),
    },
    watch: {
        getIsSpeaking(newVal) {
            if (!newVal) {
                clearTimeout(this.timeout);
                this.intervalObj = setInterval(() => {
                    this.closePercentage += 20;
                }, 4000);
                this.timeout = setTimeout(() => {
                    clearInterval(this.intervalObj);
                    this.closePercentage = 0;
                    this.$store.commit("closeJuryImageViewer");
                }, 20000);
            } else {
                this.closePercentage = 0;
                clearInterval(this.intervalObj);
                clearTimeout(this.timeout);
            }
        },
    },
    methods: {
        getImage(imageName) {
            if (imageName) {
                console.log('Image URL : ', imageName)
                return require('@/assets/' + imageName + '.png')
            }

        }
    }
};
</script>

<style scoped></style>