<template>
    <v-dialog persistent max-width="67%" v-model="getTelepresenceViewer">
        <v-card class="pa-5">
            <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="10" align-self="center" align="center">
                    <span class="justify-center text-h5 font-weight-bold ma-0">{{ $t('header') }}</span>
                </v-col>
                <v-col cols="1" align-self="center" align="center">
                    <v-btn icon @click="$store.commit('closeTelepresenceViewer')">
                        <v-icon color="red" x-large>mdi-window-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-5"></v-divider>
            <v-card-text align="center" class="pa-0 mt-5">
                <!-- <v-card width="90%" height="60" color="primary" @click="showMap()"
                    class="white--text text-h5 my-1 justify-center align-center">
                    <v-card-text class="white--text text-h5"> {{ $t('noAnsOption1') }} </v-card-text>
                </v-card> -->
                <v-card width="90%" height="60" color="primary" @click="initiateCall()"
                    class="white--text text-h5 my-1 justify-center align-center">
                    <v-card-text class="white--text text-h5"> {{ $t('option2') }} </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "telepresenceViewer",
    computed: {
        ...mapGetters(['getTelepresenceViewer', 'getMaps', 'getDefaultLocation']),
    },
    methods: {
        showMap() {
            this.$store.commit('closeTelepresenceViewer')
            var mapPath = this.getMaps.get(this.getDefaultLocation.toLowerCase())
            this.$store.dispatch('openImageViewer', {
                name: mapPath.mapName,
                url: mapPath.mapImage
            })
            this.$store.dispatch('avatarSpeak', mapPath.speech)
        },
        initiateCall() {
            this.$store.commit('closeTelepresenceViewer')
            this.$store.dispatch('initiateCall')
        }
    }
}
</script>
<i18n>
      {
    "en": {
        "header": "Select an option you prefer",
        "option1": "Go to Law Library",
        "option2": "Connect to live assistance"
      },
    "es":{
        "header": "Selecciona una opción que prefieras",
        "option1": "Ir a la Biblioteca de Derecho",
        "option2": "Conéctese a la asistencia en vivo"
      }
  }
</i18n>