<template>
  <v-row no-gutters id="avatar-wrapper" :style="{ height: height + 'px', width: width + 'px' }">
    <v-col align="center" justify="center" id="divVHSS"> </v-col>
    <div id="avatar-accessories">
      <div style="width: 650px; margin-left: 190px; " v-if="width > 600">
        <v-row no-gutters>
          <v-col cols="12" align="center" justify="center">
            <v-card class="pa-2 cardColor d-flex justify-center" height="175px" width="550px">
              <v-img src="@/assets/logo.png" width="450" contain></v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "avatar",
  props: {
    initialWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      width: this.initialWidth,
      count: 0,
      currentText: ''
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentLanguage",
      "getAvatarSpeech",
      "getIsSpeaking",
      "getDefaultBubbleText",
      "getenableADA",
      "getAvatarLangCodes",
      "getAvatarDetails",
      "getKioskId"
    ]),
    height: {
      get() {
        if (this.$route.path === "/" || this.$route.path === "/loadData") {
          // return this.width * 1.125 - 180
          return this.width - 65;
        }
        return this.width - 15;
      },
      set(newVal) {
        this.width = newVal;
      },
    },
    spVoice: function () {
      return this.getAvatarLangCodes.voiceId
    },
    spLang: function () {
      return this.getAvatarLangCodes.langId
    },
    spEngine: function () {
      return this.getAvatarLangCodes.engineId
    },
  },
  watch: {
    width: function () {
      if (this.$route.path === "/" || this.$route.path === "/loadData") {
        window.dynamicResize(this.width, this.height);
      } else {
        window.dynamicResize(this.width, this.height);
      }
    },
    getAvatarSpeech(newVal) {
      this.currentText = newVal
      this.speak(newVal);
    },
  },
  methods: {
    speak: function (speakText) {

      if (this.getIsSpeaking) window.stopSpeech();
      //window.stopSpeech()
      if (this.getCurrentLanguage === "en") {
        try {
          window.sayText(
            // '<prosody rate="+20%">' + speakText + "</prosody>",
            speakText,
            this.spVoice,
            this.spLang,
            this.spEngine
          )
          // .then(response => {
          //   console.log('Response after Speak Text: ', response)
          //   if (response.status === 1) {
          //     this.$store.dispatch("addSpeakDatatoStrapi", {
          //       outputSpeech: speakText,
          //       speechCompletion: false,
          //       timeStamp: new Date(),
          //       kioskId: this.getKioskId
          //     })
          //     //window.loadSceneByID(this.getAvatarDetails.avatarId)
          //     window.sayText(
          //       // '<prosody rate="+20%">' + speakText + "</prosody>",
          //       speakText,
          //       this.spVoice,
          //       this.spLang,
          //       this.spEngine
          //     )
          //   }
          // });
          // window.sayText(
          //   '<prosody rate="+20%">' + speakText + "</prosody>",
          //   this.spVoice,
          //   this.spLang,
          //   this.spEngine
          // );
        } catch (error) {
          console.log('Error at Speak Text: ', error.message)
        }

      } else {
        try {
          window.sayText(speakText, this.spVoice, this.spLang, this.spEngine);
        } catch (error) {
          console.log('Error at Speak: ', error.message)
        }
      }

    },
    forceResize: function () {
      window.dynamicResize(this.width, this.height);
    },
    initiateLogout(value) {
      if (this.count === 0) {
        setTimeout(() => {
          this.count = 0
        }, 40000)
      }
      if (value === '1') {
        this.count = 1
      } else {
        if (this.count >= 1) {
          this.count = this.count + 1
          if (this.count === 7) {
            // call logout
            this.count = 0
            this.$store.dispatch('logout')
          }
        }
      }
    }
  },
  mounted() {
    (window.vh_sceneLoaded = () => {
      this.forceResize();
      //window.setStatus(1, 1, 0, 0, 1);
      window.setIdleMovement(0)

      this.$store.commit("setAvatarLoaded", true);
    }),
      (window.vh_audioStarted = () => {
        console.log('Audio Started')
        window.setPlayerVolume(7)
        this.$store.commit("setIsSpeaking", true);
        this.$store.commit("setIsThinking", false)
      }),
      (window.vh_audioProgress = (percentPlayed) => {
        console.log('Audio Progress: ', percentPlayed)
      }),
      (window.vh_talkEnded = (portal) => {
        // Has to removed once all languages are added
        console.log('Audio Ended', portal)
        window.setPlayerVolume(0)
        //window.stopSpeech();
        console.log('Following Speech ended', this.currentText)
        this.$store.dispatch("addSpeakDatatoStrapi", {
          outputSpeech: this.currentText,
          speechCompletion: true,
          timeStamp: new Date(),
          kioskId: this.getKioskId
        })
        this.$store.commit("setIsSpeaking", false);
      })
  },
};
</script>

<style>
#divVHSS {
  width: 100%;
  z-index: 0 !important;
}

#divVHSS-inner {
  left: -10px !important;
  bottom: 0px !important;
}

.character,
.vhss_main_container,
._html5Player {
  /* height: 1085px !important; */
  margin-top: -10px !important;
  z-index: -1 !important;
  position: initial !important;
}

#avatar-accessories {
  z-index: 1;
  height: 83%;
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
}

.cardColor {
  background-color: black !important;
  opacity: 0.9 !important;
  border-radius: 30px !important;
}
</style>

<i18n>
{
  "en": {
      "buubletextafteraction": "Please face towards the camera, tap on the microphone button, and ask a court-related question."
    },
  "es":{
      "buubletextafteraction": "Por favor, diríjase hacia la cámara, presione el botón del micrófono y haga una pregunta relacionada con la corte."
    }
}
</i18n>