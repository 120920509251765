<template>
  <div>
    <v-row class="mt-3" no-gutters align="center" justify="center">
      <v-col cols="6">
        <v-card flat>
          <v-card-text class="d-flex justify-center pa-0">
            <v-text-field solo :placeholder="$i18n.t('placeHolder')" class="text-h5 containerBorder"
              hide-details height="55px" rounded v-model="searchKeyword" @click="expand = true"
              :append-icon="searchBarIcon" clear-icon="mdi-close-circle" @click:append="searchKeyword = ''; searchId = ''; $store.commit('setRequestQna', false)
              $store.commit('setIsThinking', false)
              $store.commit('setSpeechRecognitionHandler', false);">
            </v-text-field>
            <v-icon size="35" class="ml-2" v-if="expand" @click="expand = !expand;">mdi-keyboard-off-outline</v-icon>
          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="3" class="mt-5" align="center" v-if="!expand && searchKeyword === '' && searchId === ''">
            <v-card width="95%" class="containerBorder" :height="cardHeight">
              <v-card-title class="justify-center primary--text">{{ $t('categoryTitle') }}</v-card-title>
              <v-divider class="mx-3"></v-divider>
              <v-list class="overflow-y-auto" :height="cardHeight - 80">
                <v-list-item v-for="(   categoryName, i   ) in faqCategory   " :key="i">
                  <v-list-item-content>
                    <v-btn height="45" @click=" $store.commit('setSelectedFaqCat', { category: categoryName }); $store.commit('setRequestQna', false);
                    $store.commit('setIsThinking', false); $store.commit('setSpeechRecognitionHandler', false);"
                      v-bind:color="getSelectedFaqCat.category === categoryName ? 'secondary' : 'primary'">{{
                        categoryName
                      }}</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col :cols="colLenght" class="mt-5" align="center">
            <v-card width="95%" class="containerBorder overflow-y-auto mb-2" :height="cardHeight">
              <v-expansion-panels focusable dark class="px-5 py-3"
                v-if="!expand && searchKeyword === '' && searchId === ''" popout v-model="panel">
                <v-expansion-panel v-for="(   item, i   ) in filteredFaqs(getSelectedFaqCat.category)   " :key="i"
                  class="mt-2" :value="item.question">
                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click=" clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters v-if="item.showMap || item.showQrCode">
                      <v-col cols="9">
                        <span v-html="item.answer"></span>
                      </v-col>

                      <v-col cols="3">
                        <v-row no-gutters>
                          <v-col v-if="item.showMap" align-self="center" align="center">
                            <div v-for="(map, i) in assignedMaps(item.mapName)   " :key="i">
                              <v-btn color="secondary" @click=" showMap(map)" class="mb-3" width="85%">
                                <v-icon left dark medium> mdi-map-marker-radius </v-icon>
                                {{ map | trimLength }}
                              </v-btn>
                            </div>
                          </v-col>
                          <v-col v-if="item.showQrCode" align-self="center" align="center">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="sendLink(item.qrInfo.urllist)">
                              <v-icon left dark medium> mdi-link-variant </v-icon>
                              {{ $t('linkBtnName') }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <span v-html="item.answer"></span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels focusable dark class="px-3" popout v-else v-model="panel">
                <v-expansion-panel v-for="(   item, i   ) in matchingFaqs   " :key="i" class="mt-2"
                  :value="item.question">
                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click=" clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters v-if="item.showMap || item.showQrCode">
                      <v-col cols="9">
                        <span v-html="item.answer"></span>
                      </v-col>

                      <v-col cols="3">
                        <v-row no-gutters>
                          <v-col v-if="item.showMap" align-self="center" align="center" cols="12">
                            <div v-for="(map, i) in assignedMaps(item.mapName)   " :key="i">
                              <v-btn color="secondary" @click=" showMap(map)" class="mb-3" width="85%">
                                <v-icon left dark medium> mdi-map-marker-radius </v-icon>
                                {{ map | trimLength }}
                              </v-btn>
                            </div>
                          </v-col>
                          <v-col v-if="item.showQrCode" align-self="center" align="center" cols="12">
                            <v-btn color="secondary" class="mb-3" width="85%" @click="sendLink(item.qrInfo.urllist)">
                              <v-icon left dark medium> mdi-link-variant </v-icon>
                              {{ $t('linkBtnName') }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <span v-html="item.answer"></span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-btn class="ma-5" color="primary" width="200"
                  @click="searchKeyword = ''; searchId = ''; colLenght = 9; $store.dispatch('setDefaults'); expand = false">{{
                    $t('resetFaqs') }}</v-btn>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";
const closest_match = require("closest-match");
export default {
  name: "faqs",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      tab: 0,
      input: "",
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 690,
      colLenght: '9',
      panel: 0,
      searchId: ""
    };
  },
  computed: {
    ...mapGetters(["getQnaKeyword", "getFaqCategory", "getSelectedFaqCat", "getRequestQna"]),
    matchingFaqs() {
      let temp = [];
      if (this.searchKeyword !== "") {
        temp = this.$i18n.t("faqs").filter(faq => (faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()) || faq.category.toLowerCase().includes(this.searchKeyword.toLowerCase())))
      } else if (this.searchId !== "") {
        temp = this.$i18n.t("faqs").filter(faq => faq.linkId.toString() === this.searchId)
      } else {
        temp = this.$i18n.t("faqs")
      }
      return temp;
    },
    faqCategory() {
      return this.$i18n
        .t("faqCategory").map(category => category.categoryName)
    },
  },
  watch: {
    expand(newVal) {
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      if (newVal) {
        this.searchBarIcon = "mdi-close-circle";
        this.cardHeight = 450;
        this.colLenght = 12
      } else {
        if (this.searchKeyword !== "") {
          this.searchBarIcon = "mdi-close-circle";
          this.colLenght = 12
        } else {
          this.searchBarIcon = "mdi-magnify";
          this.colLenght = 9
        }
        this.cardHeight = 690;

      }
    },
    searchId(newVal) {
      this.panel = 0
      console.log('New Val at search ID: ', newVal)
      if (newVal !== '') {
        this.searchId = this.getQnaKeyword
      }

    },
    getQnaKeyword(newVal) {
      this.searchId = newVal
      this.colLenght = 12
      //this.searchKeyword = newVal
    },
    searchKeyword(newVal) {
      if (newVal === "") {
        this.searchBarIcon = "mdi-magnify";
        if (this.expand) {
          this.colLenght = 12
        } else {
          this.colLenght = 9
        }
      } else {
        this.searchBarIcon = "mdi-close-circle";
      }
    },
    getRequestQna(newVal) {
      if (!newVal) {
        this.$store.commit(
          "setTextBubbleText",
          "Tap the microphone button, to ask me a question."
        );
      }
    }
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    filteredFaqs(category) {
      return this.$i18n
        .t("faqs")
        .filter((faq) =>
          faq.category.toLowerCase().includes(category.toLowerCase())
        );
    },
    assignedMaps(maps) {
      let assignedMap = maps.split(",")
      return assignedMap
    },
    showMap(map) {
      window.setPlayerVolume(0)
      window.stopSpeech()
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      this.$i18n.t("maps").forEach(mapper => {
        if (mapper.mapName.toLowerCase().trim() === map.toLowerCase().trim()) {
          var floorName = this.$i18n.t('floors').filter(floor => floor.floorNumber === mapper.mapFloor)
          this.$store.dispatch("openImageViewer", {
            name: mapper.mapName,
            url: mapper.mapImage,
            floor: floorName[0].floorName,
            showQR: false,
            qrUrl: ""
          });
          this.$store.dispatch("avatarSpeak", mapper.speech);
          this.tab = mapper.mapFloor - 1
        }
      })
    },
    sendLink(details) {
      window.setPlayerVolume(0)
      window.stopSpeech()
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      this.$store.dispatch("avatarSpeak", this.$i18n.t('emailViewerSpeech'));
      this.$store.dispatch('openEmailViewer', {
        pdfType: 'link',
        pdfName: details[0].name,
        pdfUrl: details[0].link
      })
    },
    // Touch Usage
    clickedFaq(faq) {
      //this.category = faq.category
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)

      this.$store.commit("setTouchRequest", {
        module: "Frequently Asked Question",
        action: "Show Faq",
        response: faq.question,
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 25) {
        return val;
      }
      return `${val.substring(0, 21)}...`;
    },
  },
  mounted() {
    // Search Faq by keyword
    // this.searchKeyword = this.getQnaKeyword
    this.searchId = this.getQnaKeyword
    if (this.searchKeyword !== '' || this.searchId !== '') {
      this.colLenght = 12
    }
  },
};
</script>

<style>
.containerBorder {
  border: solid 3px #2c6b87 !important;
}

.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #2c6b87 !important;
  overflow-y: scroll;
  border-radius: 15px !important;
}

.linkCardBorder {
  border-color: #2c6b87 !important;
  border-bottom: solid;
  color: white !important;
  border-radius: 0px !important;
  margin-bottom: 2px;
  border-width: 0.5px;
}
</style>
<i18n>
  {
    "en": {
        "placeHolder": "Enter a keyword to search for FAQ",
        "categoryTitle": "CATEGORIES",
        "resetFaqs": "Reset to Default",
        "linkBtnName": "Get Website Link",
        "emailViewerSpeech": "Please enter your Email ID or scan the QR Code to get this link."
      },
    "es":{
        "placeHolder": "Introduzca una palabra clave para buscar preguntas frecuentes",
        "categoryTitle": "Categorías",
        "resetFaqs": "Restablecen a los predeterminados",
        "linkBtnName": "Enlace del sitio web",
        "emailViewerSpeech": "Por favor introduzca su ID de correo electrónico o escanee el código QR para obtener este enlace."
      }
  }
</i18n>