<template>
  <v-row :style="{ display: showRecognitionPage }" align="start" justify="center" no-gutters class="fill-height">
    <v-col class="text-h3" cols="12" align="center"> Recognition </v-col>
    <v-col col="12" align="center">
      <video id="fdVideo" playsinline style="
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          background-color: black;
        "></video>
    </v-col>
    <v-col cols="12">
      <v-row justify="center" class="fill-height">
        <v-col cols="6">
          <v-form ref="configForm" lazy-validation class="px-6">
            <v-row class="fill-height" no-gutters>
              <v-col cols="12" align="center" class="text-h4">Configuration</v-col>
              <v-col cols="6">
                <v-text-field label="Video Box Width" v-model="config.videoWidth" :rules="confRule" hide-details required>
                </v-text-field>
                <v-text-field label="Confidence Threshold" v-model="config.confidenceThreshold" :rules="confRule"
                  hide-details required>
                </v-text-field>
                <v-text-field label="Detection Distance" v-model="config.detectionDistance" :rules="confRule" hide-details
                  required>
                </v-text-field>
                <v-text-field label="Orientation Threshold" v-model="config.orientationThreshold" :rules="confRule"
                  hide-details required>
                </v-text-field>
                <v-btn @click="saveConfig" color="primary" dark block large>
                  Save
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Video Box Height" v-model="config.videoHeight" :rules="confRule" hide-details
                  required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="6">
          <v-row align="center" justify="center" class="fill-height text-h5" no-gutters>
            <v-col cols="12" align="center" class="text-h4 pb-4">Observations</v-col>
            <v-col align="end" cols="6">Face Detected</v-col><v-col align="start" cols="6" class="pl-5">{{
              faceDetected
            }}</v-col>
            <v-col align="end" cols="6">Face Confidence</v-col><v-col align="start" cols="6" class="pl-5">{{
              faceProbability }}%</v-col>
            <v-col align="end" cols="6">Face Count</v-col><v-col align="start" cols="6" class="pl-5">{{ faceCount
            }}</v-col>
            <v-col align="end" cols="6">Face Distance</v-col><v-col align="start" cols="6" class="pl-5">{{
              faceDistanceMeters }} Meters</v-col>
            <v-col align="end" cols="6">Face Orientation</v-col><v-col align="start" cols="6" class="pl-5">{{
              faceOrientation
            }}</v-col>
            <v-col align="end" cols="6">Right EE Distance</v-col><v-col align="start" cols="6" class="pl-5">{{
              reeDistance
            }}</v-col>
            <v-col align="end" cols="6">Left EE Distance</v-col><v-col align="start" cols="6" class="pl-5">{{
              leeDistance
            }}</v-col>
            <v-col align="end" cols="6">Left Eye Ear</v-col><v-col align="start" cols="6" class="pl-5">{{
              leftEye }} - {{ leftEar
  }}</v-col>
            <v-col align="end" cols="6">Right Eye Ear</v-col><v-col align="start" cols="6" class="pl-5">{{
              rightEye }} - {{ rightEar
  }}</v-col>
            <v-col align="end" cols="6"></v-col>
            <v-col align="center" cols="6"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import * as tf from "@tensorflow/tfjs";
const blazeFace = require("@tensorflow-models/blazeface");
import { mapGetters } from "vuex";
import router from "@/router";
export default {
  name: "recognition",
  data() {
    return {
      fdVideo: null,
      returnTensors: false,
      flipHorizontal: true,
      annotateBoxes: true,
      faceDetected: false,
      faceProbability: 0,
      faceDistance: 0,
      faceCount: 0,
      faceDistanceMeters: 0,
      faceOrientation: undefined,
      reeDistance: 0,
      leeDistance: 0,

      // Temporary
      leftEye: 0,
      leftEar: 0,
      rightEye: 0,
      rightEar: 0,
      //
      confRule: [(v) => !!v || "Cannot left empty"],
      config: {
        videoWidth: 600,
        videoHeight: 500,
        frameRate: 20,
        confidenceThreshold: 98,
        detectionDistance: 1.1,
        orientationThreshold: 10,
      },
      speechRecognition: undefined,
      transcript: "",
      finalTranscript: "",
      speechtimeout: null,
      speechResetTimeout: null,
      stopSpeechTimer: 1,
      onResult: false,
    };
  },
  computed: {
    ...mapGetters([
      "enableSpeechRecognition",
      "enableFaceDetection",
      "speechTranscript",
      "speechRecognitionHandler",
      "enableAllRecognition",
      "showRecognitionPage",
      "getGreetingStatus",
      "getResetKioskStatus",
      "getTouchCount",
      "getKioskProfile",
      "speechRecognitionLanguage",
      "getCurrentLanguage",
      "getAvatarDetails",
      "getRequestQna",
      "getAvatarLangCodes",
      "isUserLooking",
      "speechRecognitionStatus"
    ]),
  },
  watch: {
    enableAllRecognition(newVal) {
      if (newVal) {
        this.setupFaceDetection();
        this.setupSpeechRecognition();
      } else {
        this.$store.commit("setEnableSpeechRecognition", false);
        this.$store.commit("setEnableFaceDetection", false);
      }
    },
    getCurrentLanguage(newVal) {
      this.setupSpeechRecognition();
    },
    enableSpeechRecognition(newVal) {
      if (newVal) {
        this.startSpeechRecognition();
      } else {
        this.stopSpeechRecognition();
      }
    },
    speechRecognitionHandler(newVal) {
      if (newVal) {
        this.startSpeechRecognition();
      } else if (!newVal) {
        this.stopSpeechRecognition();
      }
    },
    $route(to, from) {
      if (to.path === "/rec")
        this.$store.commit("setShowRecoginitionPage", "block");
      if (from.path === "/rec")
        this.$store.commit("setShowRecoginitionPage", "none");
    },
    getGreetingStatus(newVal) {
      if (!newVal) {
        this.$i18n.locale = "en";
      }
    },
    getTouchCount(newVal) {
      if (newVal === 0) {
        this.$i18n.locale = "en";
      }
    },
  },
  methods: {
    setupFaceDetection() {
      this.fdVideo = document.getElementById("fdVideo");
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: this.config.videoWidth,
            height: this.config.videoHeight,
            frameRate: { max: this.frameRate },
          },
        })
        .then((stream) => {
          this.fdVideo.srcObject = stream;
          this.fdVideo.play().then(() => {
            this.fdVideo.width = this.fdVideo.videoWidth;
            this.fdVideo.height = this.fdVideo.videoHeight;
            tf.setBackend("webgl").then(() => {
              console.log("Webgl is the backend");
              blazeFace.load().then((model) => {
                console.log("BlazeFace loaded");
                setInterval(() => {
                  if (this.enableFaceDetection) this.prediction(model);
                }, 200);
              });
            });
          });
        });
    },
    prediction(model) {
      model
        .estimateFaces(
          this.fdVideo,
          this.returnTensors,
          this.flipHorizontal,
          this.annotateBoxes
        )
        .then((predictions) => {
          this.$store.commit("setFaceDetectionStatus", true);
          if (predictions.length > 0 && predictions[0].probability[0] > 0.95) {
            this.faceCount = predictions.length;
            this.$store.commit("setFaceCount", this.faceCount);
            this.faceDetected = true;
            for (let i = 0; i < this.faceCount; i++) {
              this.faceProbability = (
                predictions[i].probability[0] * 100
              ).toFixed(1);
              const faceBoxStart = predictions[i].topLeft;
              const faceBoxEnd = predictions[i].bottomRight;
              this.faceDistance = (
                ((faceBoxStart[0] - faceBoxEnd[0]) / this.fdVideo.width) *
                100
              ).toFixed(2);
              this.faceDistanceMeters = (
                this.scale(this.faceDistance, 90, 20, -10, 100) / 100
              ).toFixed(2);
              if (
                this.faceDistanceMeters <= this.config.detectionDistance &&
                this.faceProbability >= this.config.confidenceThreshold
              ) {
                this.$store.commit("setIsUserApproached", true);
                this.rightEye = predictions[i].landmarks[4][0].toFixed(2)
                this.rightEar = predictions[i].landmarks[0][0].toFixed(2)
                this.leftEye = predictions[i].landmarks[1][0].toFixed(2)
                this.leftEar = predictions[i].landmarks[5][0].toFixed(2)
                this.reeDistance = (
                  predictions[i].landmarks[4][0] -
                  predictions[i].landmarks[0][0]
                ).toFixed(2);
                this.leeDistance = (
                  predictions[i].landmarks[1][0] -
                  predictions[i].landmarks[5][0]
                ).toFixed(2);
                // if (
                //   this.reeDistance < this.config.orientationThreshold ||
                //   this.leeDistance < this.config.orientationThreshold
                // ) 
                if (this.reeDistance > 80 || this.leeDistance > 80) {
                  this.$store.commit("setIsUserLooking", false);
                  if (this.reeDistance > this.leeDistance) {
                    this.faceOrientation = "Right Facing";
                  } else {
                    this.faceOrientation = "Left Facing";
                  }
                } else {
                  if (!this.getGreetingStatus && this.getTouchCount === 0) {
                    this.$store.commit("setDefaultBubbleText", true);
                    this.$store.dispatch(
                      "avatarSpeak",
                      this.$i18n.t("introSpeech")
                    );
                    this.$store.commit("setGreetingStatus", true);
                    this.$store.commit("setStartUserSession", new Date());

                    this.$store.commit("setTouchRequest", {
                      module: "Default",
                      action: "Face Detected",
                      response: "User Session Started - Face Detected",
                      timeStamp: new Date(),
                      requestType: "Session",
                    });
                  } else if (
                    this.getTouchCount > 0 &&
                    !this.getGreetingStatus
                  ) {
                    //if (!this.getResetKioskStatus) {
                    this.$store.commit("setResetKioskStatus", false);
                    this.$store.commit("setGreetingStatus", true);
                    this.$store.dispatch("clearResetTimeout");
                    // }
                    this.$store.commit("setTouchRequest", {
                      module: "Default",
                      action: "Face Detected",
                      response: "Session changed to Face Detection Handling",
                      timeStamp: new Date(),
                      requestType: "Session",
                    });
                  } else if (
                    this.getTouchCount >= 0 &&
                    this.getGreetingStatus
                  ) {
                    //if (!this.getResetKioskStatus) {
                    this.$store.commit("setResetKioskStatus", false);
                    this.$store.dispatch("clearResetTimeout");
                    //}
                  }
                  this.faceOrientation = "Center Facing";
                  this.$store.commit("setIsUserLooking", true);
                }
              } else {
                this.reeDistance = 0;
                this.leeDistance = 0;
                this.faceOrientation = undefined;
                this.$store.commit("setIsUserApproached", false);
                if (this.getGreetingStatus && !this.getResetKioskStatus) {
                  if (router.currentRoute.path !== '/telepresence') {
                    this.$store.dispatch("resetKiosk");
                    this.$store.commit('setResetKioskStatus', true)
                  }
                }
              }
            }
          } else {
            this.faceDistance = 0;
            this.faceProbability = 0;
            this.faceDistanceMeters = 0;
            this.faceDetected = false;
            this.$store.commit("setIsUserApproached", false);
            this.$store.commit("setIsUserLooking", false);
            this.$store.commit("setFaceCount", 0);
            if (this.getGreetingStatus && !this.getResetKioskStatus) {
              this.$store.dispatch("resetKiosk");
              this.$store.commit('setResetKioskStatus', true)
            }
          }
        });
    },
    scale(number, inMin, inMax, outMin, outMax) {
      return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },
    setupSpeechRecognition() {
      const speechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const SpeechGrammarList =
        window.SpeechGrammarList || window.webkitSpeechGrammarList;
      if (!speechRecognition) console.log("Speech Recognition not supported");
      //has to be changed court wise
      var nouns = [
        "Settlement Conference Room",
        "Judge",
        "Evictions",
        "Self Help",
        "Counsel",
        "Criminal", "Vending Machine",
        "Bar Association", "Hope Works",
        "Cafe", "Commissioner's", "House of Ruth",
        "Forms", "Calendar Management", "Juvenile", "Law Library",
        "Clerk", "Community Meeting Room", "Public Defender", "Parole", "Probation",
        "Register of Wills", "General Services", "Liaison", "Pretrial",
        "Administrator", "Drinking Driver Monitor Program", "Serenity Room",
        "Office", "Social Services", "State's Attorneys",
        "Maps",
        "Family Law Counter",
        "Jury Assembly Office",
        "Elevator",
        "Bathroom",
        "Facilities",
        "Maintenance",
        "Human Resource",
        "Innovation",
        "Sheriff", "DV",
        "Water Resource Commissioner",
        "Parks and Recreation",
        "Planning and Performance Improvement", "protective", "peace", "domestic", "DV", "protection", "violence", "Chloe", "Jada", "failure", "missed",
        "breach", "lease", "tenant", "escrow", "wills", "will", "small claims", "liaison", "case", "tenant", "landlord"
        , "parole", "ruth", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "courtroom", "street", "crimes"];
      //var grammar = '#JSGF V1.0; grammar nouns; public <nouns> = ' + nouns.join(' | ') + ' ;∂';
      var grammar =
        "#JSGF V1.0; grammar nouns; public <nouns> = " + nouns.join(" | ") + ';';

      this.speechRecognition = new speechRecognition();
      const speechRecognitionList = new SpeechGrammarList();
      speechRecognitionList.addFromString(grammar, 1);
      this.speechRecognition.grammars = speechRecognitionList;
      this.speechRecognition.lang = this.speechRecognitionLanguage;
      this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;
      this.processSpeechRecogniton();
    },
    processSpeechRecogniton() {
      this.speechRecognition.onstart = () => {
        this.onResult = false;
        console.log('Recognition Started', this.onResult)
        if (!this.onResult) {
          this.$store.commit("setTextBubbleText", this.$i18n.t("listeningMsg"));
        }
        this.speechResetTimeout = setTimeout(() => {
          this.stopSpeechRecognition(); this.$store.commit(
            "setTextBubbleText",
            this.$i18n.t("afterSpeechText")
          );
        }, 15000)
        this.$store.commit("setSpeechRecognitionStatus", true);
      };

      this.speechRecognition.onend = () => {
        console.log('Speech Recognition Ended after looking here in there.', this.onResult)
        if (!this.onResult) {
          this.$store.commit(
            "setTextBubbleText",
            this.$i18n.t("afterSpeechText")
          );
          this.$store.commit("setSpeechRecognitionHandler", null);
          this.$store.commit("setSpeechRecognitionStatus", false);
          clearTimeout(this.speechResetTimeout)
          clearTimeout(this.speechtimeout)
          //this.onResult = false;
        } else {
          if (this.stopSpeechTimer !== 0) {
            this.stopSpeechTimer = 1
            if (this.getRequestQna) {
              //this.startSpeechRecognition()
            } else {
              this.$store.commit("setSpeechRecognitionHandler", null);
              this.$store.commit("setSpeechRecognitionStatus", false);
              this.onResult = false
            }
          } else {
            this.$store.commit("setSpeechRecognitionHandler", null);
            this.$store.commit("setSpeechRecognitionStatus", false);
            this.onResult = false
          }
        }
      };

      // this.speechRecognition.onnomatch = () => {
      //   console.log('No recognition detected')
      // };

      this.speechRecognition.onresult = (event) => {
        let spResult = "";
        let spTranscript = "";
        //if (this.isUserLooking) {
          this.onResult = true
          spResult = event.results[event.resultIndex];
          spTranscript =
            event.results[event.results.length - 1][0].transcript;
          this.transcript = spTranscript
          this.stopSpeechTimer = 1
          this.$store.commit("setTextBubbleText", this.transcript);
          console.log('Transcript length: ', this.transcript.split(" ").length)
          clearTimeout(this.speechtimeout)
        //} else {
          //console.log('Coming in here when not looking', this.transcript)
          //temp += this.transcript
          //console.log('What is in temp: ', temp)
        //}
        if (spResult !== "" && this.isUserLooking) {
          if (spResult.isFinal) {
            // console.log('Coming inside isFinal: ', spResult, this.isUserLooking)
            // console.log('SP Result: ', spResult[0].confidence)
            clearTimeout(this.speechResetTimeout)
            this.onResult = true
            if (spTranscript.split(" ").length <= 25) {
              this.finalTranscript = this.finalTranscript + " " + this.transcript
              this.transcript = this.finalTranscript
              this.speechtimeout = setInterval(() => {
                if (this.stopSpeechTimer !== 0) {
                  this.stopSpeechTimer = this.stopSpeechTimer - 1
                } else if (this.stopSpeechTimer === 0) {
                  clearTimeout(this.speechtimeout)
                  this.stopSpeechRecognition()
                  this.stopSpeechTimer = 0
                  //this.$store.dispatch("gptIntegration", this.transcript)
                  // console.log('What is the value of transcript: ', this.transcript)
                  if (this.getRequestQna) {
                    if (this.transcript.trim() !== "") {
                      this.$store.dispatch("requestQnA", this.transcript)
                    } else {
                      this.$store.commit(
                        "setTextBubbleText",
                        this.$i18n.t("afterSpeechText")
                      );
                    }
                  }

                  this.transcript = ""
                  this.finalTranscript = ""
                }
              }, 700)
            } else {
              this.$store.commit("setSpeechTranscript", "");
              this.$store.dispatch(
                "avatarSpeak",
                this.$i18n.t("longQuestionError")
                
              );
            }
          } else {
            //console.log('coming in here if no speech is detected')
          }
        } else {
          //clearTimeout(this.speechtimeout)
          if (this.stopSpeechTimer === 1) {
            console.log('Coming in the final else')
            this.stopSpeechTimer = 0
            this.speechtimeout = setTimeout(() => { this.stopSpeechRecognition() }, 8000)
          }
          this.onResult = false
          // if (this.transcript.split(" ").length > 0) {
          //   this.onResult = true
          // } else {
          //   this.onResult = false
          // }

        }

      };
    },
    startSpeechRecognition() {
      try {
        this.speechRecognition.stop();
        this.speechRecognition.start();
        this.$store.commit('setRequestQna', true)

        // this.processSpeechRecogniton();
      } catch (error) {
        console.log(error.message);
      }
    },
    stopSpeechRecognition() {
      try {
        console.log('Coming inside stop recognition.')
        this.speechRecognition.stop();
        this.$store.commit("setSpeechRecognitionHandler", null);
        this.$store.commit("setSpeechRecognitionStatus", false);
        this.stopSpeechTimer = 1
      } catch (error) {
        console.log(error.message);
      }
    },
    saveConfig() {
      if (this.$refs.configForm.validate()) {
        this.$store.dispatch("saveConfig", this.config).then(
          (response) => {
            window.location.reload();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
  },
  mounted() {
    console.log("REC Mounted");
    // ToDo - Move this to watch to be controlled by allRecognition Flag
    // this.setupFaceDetection()
    // this.setupSpeechRecognition()
  },
};
</script>

<style></style>
<i18n>
  {
    "en": {
        "listeningMsg": "I am listening...",
        "introSpeech": "Hi, I'm here to help. Please tap on the microphone button on the upper right and ask me your question.",
        "afterSpeechText": "Please face towards the camera, tap on the microphone button, and ask a court-related question.",
        "longQuestionError": "I didn't quite get that. Could you please ask the question again?"
      },
    "es":{
      "listeningMsg": "Estoy escuchando...",
      "introSpeech": "Hola, estoy aquí para ayudarlo. Toque el botón de micrófono en la parte superior derecha y hágame su pregunta.",
      "afterSpeechText": "Por favor, diríjase hacia la cámara, presione el botón del micrófono y haga una pregunta relacionada con la corte.",
      "longQuestionError": "No entendí eso. ¿Podrías hacer la pregunta otra vez?"
      }
  }
  </i18n>