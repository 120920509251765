<template>
    <v-dialog v-model="getJuryOptionsViewer.show" width="55%" persistent>
      <v-card class="pa-5">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="1"></v-col>
          <v-col cols="10" align-self="center" align="center">
            <span class="justify-center text-h5 font-weight-bold">
              {{ getJuryOptionsViewer.dialogHeader }}
            </span>
          </v-col>
          <v-col cols="1" align-self="center" align="center">
            <v-btn icon @click="closeDialog()">
              <v-icon color="red" x-large>mdi-window-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider class="my-2"></v-divider>
          </v-col>
        </v-row>
        <v-card-text align="center" class="pa-0 mt-2">
          <v-card v-for="(item, index) in getJuryOptionsViewer.dialogData" :key="index" class="my-2" width="95%"
            @click="viewElement(item)" color="primary">
            <v-card-text class="white--text text-h6">
              <span v-if="typeof item === 'object'">
                {{ item.displayText }}
              </span>
              <span v-else>
                {{ item }}
              </span>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    name: "juryOptionsViewer",
    data() {
      return {
        talkButtonSrc: "talkButton.png",
      };
    },
    computed: {
      ...mapGetters([
        "getJuryOptionsViewer",
        "getQnaStatus",
        "getResetKioskStatus"
      ]),
    },
    methods: {
      viewElement(input) {
        // Touch Usage
        this.$store.commit("setTouchRequest", {
          module: "Options Viewer",
          action: input,
          response: "Action executed on touch",
          timeStamp: new Date(),
          requestType: "Touch",
        });
        console.log('Input: ', input)
        this.$store.dispatch("requestQnA", input);
        this.$store.commit("closeJuryOptionsViewer");
      },
      closeDialog() {
        this.$store.commit("closeJuryOptionsViewer");
      }
    }
  };
  </script>
  
  <style scoped>
  .talk-button {
    filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
  }
  </style>
  