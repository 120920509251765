<template>
  <v-row class="fill-height">
    <v-container class="fill-height justify-center my-auto" fluid>
      <v-card width="400">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-img
            src="@/assets/logo.png"
            contain
            width="400"
          >
          </v-img>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear
          v-show="loading"
          indeterminate
          color="primary"
          background-opacity="0"
        >
        </v-progress-linear>
        <v-card-text>
          <v-fade-transition>
            <v-form ref="logInForm" lazy-validation class="px-6">
              <v-text-field
                v-model="logInForm.email"
                label="E-mail"
                :rules="rules.emailRules"
              >
              </v-text-field>
              <v-text-field
                v-model="logInForm.password"
                :type="passwordFieldType"
                label="Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="toggleshowPassword"
                :rules="rules.passwordRule"
                @keyup.enter="login"
              >
              </v-text-field>
              <v-btn
                color="primary"
                dark
                block
                large
                @click="login"
                @keyup.enter="login"
              >
                Kiosk Login
              </v-btn>
            </v-form>
          </v-fade-transition>
        </v-card-text>
      </v-card>
    </v-container>
  </v-row>
</template>

<script>

export default {
  name: "login",
  data() {
    return {
      showPassword: false,
      passwordFieldType: "password",
      loading: false,
      logInForm: {
        email: "",
        password: "",
      },
      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRule: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 8) || "Password must be 8 characters long",
        ],
      },
    };
  },
  methods: {
    login() {
      if (this.$refs.logInForm.validate()) {
        this.loading = true;
        this.$store.dispatch("login", this.logInForm).then(
          (response) => {
            this.loading = false;
            this.showPassword = false;
            console.log(response);
          },
          (error) => {
            this.loading = false;
            console.error(error);
          }
        );
      }
    },
    toggleshowPassword() {
      this.showPassword = !this.showPassword;
      this.passwordFieldType = this.showPassword ? "text" : "password";
    },
  },
};
</script>