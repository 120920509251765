
module.exports = {
  firebase: {
    apiKey: "AIzaSyD6dAxRRkZDZMR5lCAdcV20JP2KWCPYLAM",
    authDomain: "maryland-eb75e.firebaseapp.com",
    databaseURL: "https://maryland-eb75e-default-rtdb.firebaseio.com",
    projectId: "maryland-eb75e",
    storageBucket: "maryland-eb75e.appspot.com",
    messagingSenderId: "924352603209",
    appId: "1:924352603209:web:a5a9177d19e44734ec8b98"
  }
}

